<div class="container-fluid">
  <div class="row mt-5">
    <div class="offset-lg-2 offset-md-1 col-lg-8 col-md-10 col-sm-12">
      <div class="card">
        <h5 class="card-header">Question 3 - Angular v11 SPA - development mode</h5>
        <div class="card-body">
          <h5 class="card-title mb-4">In this angular application the requested password is stored in the root component's property "password". Retrieve the password and proceed.</h5>
          <div class="card-text mt-4 mb-3">
            <div class="mt-5 row">
              <label for="q3" class="col-sm-2 col-form-label">Password</label>
              <div class="col-sm-10">
                <input type="password" class="form-control" id="q3" [(ngModel)]="inputPw">
              </div>
            </div>
          </div>
          <div id="valid-alert" class="alert alert-success my-4" [ngClass]="{'visually-hidden': !isValid}" role="alert">
            Well done! Click 'next' to proceed.
          </div>
          <div id="invalid-alert" class="alert alert-danger my-4" [ngClass]="{'visually-hidden': (isValid || !isDirty)}" role="alert">
            Not the answer(s) we are searching for - please try again
          </div>
          <div class="card-text mb-3">
            <div id="result">
            </div>
          </div>
          <a id="validate-button" href="#" class="float-end btn btn-primary" [ngClass]="{'visually-hidden': isValid}" (click)="validate()">Validate</a>
          <a id="proceed-button" href="https://quiz.avitium.io/q4/index.html" class="float-end btn btn-success" [ngClass]="{'visually-hidden': !isValid}">Proceed</a>
        </div>
      </div>
    </div>
  </div>
</div>
