import { Component } from '@angular/core';

const PLAIN_PW = 'we_process_turnarounds_in_realtime';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Avitium Onboarding Quiz';

  password = PLAIN_PW;
  inputPw = '';

  isValid = false;
  isDirty = false;

  validate(): void {
    if (!this.isDirty) {
      this.isDirty = true;
    }
    this.isValid = this.inputPw === PLAIN_PW;
  }
}
